<template>
  <div class="list-item" @click="$emit('click')">
    <div class="list-item-icon"><iconic :name="icon" /></div>
    <div class="list-item-text" v-html="text"></div>
  </div>
</template>

<script>
export default {
  props: ["icon", "text"],
};
</script>

<style lang="scss" scoped>
.list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: $mpadding/2 $mpadding/2;
  &:hover {
    background-color: #e9e9e9;
    font-weight: bold;
  }
  &-icon {
    @include Flex(inherit, center, center);
    width: $mpadding * 2;
    height: $mpadding * 2;
    min-width: $mpadding * 2;
    background-image: var(--flag);
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    font-size: $mpadding * 2;
  }
  &-text {
    margin: 0 0 0 $mpadding;
  }
}
</style>
